<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="8" order-md="0">
        <!-- <search-form class="mb-4" /> -->
        <site-cards-list />
      </v-col>
      <v-col order-md="1">
        <alerts-table v-if="alerts.length > 0" :alerts="alerts" />
        <v-card v-else>
          <v-card-title>Alertas</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AlertsTable from "@/components/AlertsTable.vue";
import SiteCardsList from "./parts/SiteCardsList.vue";
// import SearchForm from './parts/SearchForm.vue'
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "Overview",
  components: {
    AlertsTable,
    SiteCardsList,
    // SearchForm
  },
  data() {
    return {
      alarm: null,
      pollingTimer: null,
    };
  },
  computed: {
    ...mapGetters(["sites", "devComms", "alerts", "loggedUser"]),
  },
  async created() {
    try {
      this.loadSites();
      // this.getAlarm()

      this.refreshData();
      this.pollingTimer = setInterval(this.loadSites, 30000);
    } catch (error) {
      alert("Ocorreu um erro ao carregar dados");
    }
  },
  destroyed() {
    clearInterval(this.pollingTimer);
  },
  methods: {
    async getAlarm() {
      await axios
        .get("alarm")
        .then(res => {
          let result = res.data.map(({ id, status, time }) => ({
            id,
            time: this.$options.filters.formatTime(time),
            status,
          }));
          this.alarm = result;
        })
        .catch(err => {
          console.log(err);
        });
    },
    ...mapActions(["loadSites", "loadDevComms", "loadAlerts"]),
    refreshData() {
      // this.loadDevComms();
      // this.loadAlerts();
      // this.getAlarm()
    },
  },
};
</script>
