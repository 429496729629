<template>
  <v-col xl="3" md="4" sm="4">
    <v-card
      :class="cardStyle ? 'alert mx-auto site-card'  : '' + ' mx-auto site-card'"
      outlined
      ref="card"
      elevation="2"
      max-width="300"
      hover
      :style="cardStyle"
      :color="this.$vuetify.theme.currentTheme.primary"
      @click="onSiteClick"
    >
      <v-list-item class="card">
        <v-list-item-content>
          <div class="overline mb-4">
            <v-flex class="d-flex justify-md-space-between justify-sm-space-between">
              <span> id: {{ site._id }} </span>
              <v-icon v-if="site.commFlag != 1" color="red !important">
                {{ mdiAntenna }}
              </v-icon>
            </v-flex>
          </div>
          <v-flex class="pa-4 mb-4">
            <v-list-item-title class="headline mb-1">
              {{ site.title }}
            </v-list-item-title>
            <v-card-text class="pl-0">
              <v-list-item-subtitle>Ultima atualização</v-list-item-subtitle>
              <span class="mr-3">
                {{ site.updateTime[1] | formatDate }}
              </span>
              <span>
                {{ site.updateTime[1] | formatTime }}
              </span>
            </v-card-text>
            <v-list-item-subtitle class="text-center mt-3"
              >({{ site.updateTime | formatDuration }})</v-list-item-subtitle
            >
          </v-flex>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-col>
</template>

<script>
import { mdiAntenna } from "@mdi/js";
import { mapActions } from "vuex";
import { ROUTE_NAME } from "@/router";

export default {
  name: "SiteCard",
  props: {
    site: {
      type: Object,
      required: true,
    },
    devComm: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      mdiAntenna,
    };
  },
  computed: {
    cardStyle() {
      if (this.site) {
        switch (this.site.alarmState) {
          case 1:
            return `
            border-top-color: ${this.$vuetify.theme.currentTheme.warning};
            border-top-width: 5px;`;
          case 2:
            return `border-top-color: ${this.$vuetify.theme.currentTheme.error};
            border-top-width: 5px;`;
          default:
            return "";
        }
      }

      return "";
    },
  },
  methods: {
    ...mapActions(["setActiveSite"]),
    onSiteClick() {
      this.setActiveSite(this.site);
      this.$router.push({
        name: ROUTE_NAME.SITE_DASHBOAD,
        params: {
          siteId: this.site._id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.alert.v-card {
  /* border-top: .4rem solid !important; */
  /* border-width: 5px; */
  /* border-top-color: rgb(228, 13, 13) !important; */
  border-radius: 8px;
}

.site-id {
  width: 3em;
  text-align: center;
}
.last-update {
  width: 10em;
}
</style>
