<template>
  <div class="site-cards-list">
    <v-row>
      <site-card
        v-for="site in sites"
        :key="site._id"
        :site="site"
        :dev-comm="getDevComm(site._id)"
      />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SiteCard from "./SiteCard.vue";

export default {
  name: "SiteCardsList",
  components: {
    SiteCard,
  },
  computed: {
    ...mapGetters(["sites", "devComms"]),
  },
  methods: {
    getDevComm(_id) {
      return this.devComms.find((devComm) => devComm._id === _id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins";

.site-cards-list {
  @include md-and-up {
    /* overflow-y: scroll;
    height: calc(100vh - 158px); */
    padding-right: 16px;
  }
}
.site-alert:not(:last-child) {
  margin-bottom: 16px;
}
</style>
